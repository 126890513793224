import React, { useCallback, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Svg } from '@mentimeter/ragnar-visuals';
import { useRagnar } from '@mentimeter/ragnar-react';
import { blackBase, whiteBase } from '@mentimeter/ragnar-colors';

interface ICarouselIndicator {
  activeItem: number;
  length: number;
  radius?: number;
  offset?: number;

  onChangeSlide(index: number): void;
}

export const INDICATOR_DEFAULT_RADIUS = 7;
export const INDICATOR_DEFAULT_DESKTOP_OFFSET = 36;
export const INDICATOR_DEFAULT_MOBILE_OFFSET = 30;
const INDICATOR_ANIMATION_DELAY = 50;

const CarouselIndicator = ({
  activeItem,
  length,
  onChangeSlide,
  radius = INDICATOR_DEFAULT_RADIUS,
  offset = INDICATOR_DEFAULT_MOBILE_OFFSET,
}: ICarouselIndicator) => {
  const indicatorDiameter = radius * 2;
  const indicatorsWidth = indicatorDiameter * length + offset * (length - 1);
  const { theme } = useRagnar();
  const getIndicatorPosition = useCallback(
    (nextIndex: number) => radius + (indicatorDiameter + offset) * nextIndex,
    [indicatorDiameter, offset, radius],
  );
  const [indicatorStyles, setIndicatorStyles] = useSpring(() => ({
    x1: getIndicatorPosition(activeItem),
    x2: getIndicatorPosition(activeItem),
  }));

  useEffect(() => {
    setIndicatorStyles.start({
      x1: getIndicatorPosition(activeItem),
    });
    setIndicatorStyles.start({
      x2: getIndicatorPosition(activeItem),
      delay: INDICATOR_ANIMATION_DELAY,
    });
  }, [activeItem, getIndicatorPosition, setIndicatorStyles]);

  /*
    TODO: Fix fallback for 6+ indicators on mobile
    possible solution is to move viewbox calculating full and rendered with via ref
    and add transition to it
  */
  if (length <= 1) {
    return null;
  }
  return (
    <Svg
      aria-hidden={true}
      viewBox={`0 0 ${indicatorsWidth} ${indicatorDiameter}`}
      height={indicatorDiameter}
      width={indicatorsWidth}
    >
      {Array.from(Array(length)).map((_, index) => (
        <circle
          key={index}
          onClick={() => onChangeSlide(index)}
          fill={whiteBase}
          stroke={blackBase}
          cx={getIndicatorPosition(index)}
          cy={radius}
          r={radius - 1}
          cursor="pointer"
        />
      ))}
      <animated.line
        {...indicatorStyles}
        y1={radius}
        y2={radius}
        stroke={theme.colors.primary}
        strokeLinecap="round"
        strokeWidth={indicatorDiameter}
        pointerEvents="none"
      />
    </Svg>
  );
};

export default CarouselIndicator;
