import React, { type JSX } from 'react';
import type { SpringValue } from 'react-spring';
import { animated } from 'react-spring';
import { SwipeGesture } from '@mentimeter/swipe-gesture';
import { Box, Clickable } from '@mentimeter/ragnar-ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@mentimeter/ragnar-visuals';
import { gray100 } from '@mentimeter/ragnar-colors';
import { getPrevSlide, getNextSlide } from './Carousel';
import { cardMarginDesktop, cardMarginMobile } from './Card';

interface IHeroCarousel {
  content: JSX.Element[];
  carouselWrapperRef: React.RefObject<HTMLDivElement | null>;
  carouselStyles: {
    x: SpringValue<number>;
  };
  width: number;
  activeItem: number;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
}

interface HeroPaginationButtonProps {
  onClick: () => void;
  label: string;
  disabled: boolean;
  children: React.ReactNode;
}

const HeroPaginationButton = ({
  disabled,
  label,
  children,
  onClick,
}: HeroPaginationButtonProps) => (
  <Clickable
    aria-label={label}
    onClick={onClick}
    alignItems="center"
    justifyContent="center"
    disabled={disabled}
    bg={gray100}
    p="space2"
    borderRadius="16px"
  >
    {children}
  </Clickable>
);

const AnimatedContainer = animated(Box);

const HeroCarousel = ({
  content,
  carouselWrapperRef,
  carouselStyles,
  width,
  activeItem,
  setActiveItem,
}: IHeroCarousel) => {
  const isFirst = activeItem !== 0;
  const isLast = activeItem !== content.length - 1;
  const prevSlide = getPrevSlide(activeItem, content.length);
  const nextSlide = getNextSlide(activeItem, content.length);

  return (
    <>
      <Box alignItems="center" flexDirection="row">
        {isFirst && (
          <Clickable
            aria-label={`Go to slide ${prevSlide + 1} of ${content.length}`}
            onClick={() => setActiveItem(prevSlide)}
            display={['none', null, 'inherit']}
            position="absolute"
            left="-68px"
            zIndex={10}
          >
            <ArrowLeftIcon size={7} />
          </Clickable>
        )}
        <Box width={1} ref={carouselWrapperRef}>
          <SwipeGesture
            onSwipe={(direction) =>
              direction === 'swipe-left'
                ? setActiveItem(nextSlide)
                : setActiveItem(prevSlide)
            }
          >
            <AnimatedContainer
              minWidth="100%"
              flexDirection="row"
              alignItems="stretch"
              gap={[cardMarginMobile, cardMarginDesktop]}
              style={carouselStyles}
            >
              {content.map((slide, index) => (
                <Box
                  key={index}
                  width={width || '100%'}
                  display={index && !width ? 'none' : 'inherit'}
                >
                  {slide}
                </Box>
              ))}
            </AnimatedContainer>
          </SwipeGesture>
        </Box>
        {isLast && (
          <Clickable
            aria-label={`Go to slide ${nextSlide + 1} of ${content.length}`}
            onClick={() => setActiveItem(nextSlide)}
            display={['none', null, 'inherit']}
            position="absolute"
            right="-68px"
            zIndex={10}
          >
            <ArrowRightIcon size={7} />
          </Clickable>
        )}
      </Box>
      <Box
        display={['inherit', null, 'none']}
        mt="space6"
        flexDirection="row"
        gap="space2"
      >
        <HeroPaginationButton
          label={`Go to slide ${prevSlide + 1} of ${content.length}`}
          onClick={() => setActiveItem(prevSlide)}
          disabled={!isFirst}
        >
          <ArrowLeftIcon size={2} />
        </HeroPaginationButton>
        <HeroPaginationButton
          label={`Go to slide ${nextSlide + 1} of ${content.length}`}
          onClick={() => setActiveItem(nextSlide)}
          disabled={!isLast}
        >
          <ArrowRightIcon size={2} />
        </HeroPaginationButton>
      </Box>
    </>
  );
};

export default HeroCarousel;
