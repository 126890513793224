import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { Light } from '@mentimeter/ragnar-ui';
import type { PropsWithChildren } from 'react';

export const cardMarginMobile = 24;
export const cardMarginDesktop = 118;

interface CardProps {
  width: BoxT['width'];
  hide: boolean;
}

const Card = ({ children, width, hide }: PropsWithChildren<CardProps>) => {
  return (
    <Box
      extend={() => ({
        ':not(:first-child)': {
          marginLeft: `${cardMarginMobile}px`,
          '@media screen and (min-width: 600px)': {
            marginLeft: `${cardMarginDesktop}px`,
          },
        },
      })}
    >
      <Light>
        <Box
          alignItems="stretch"
          width={width}
          justifyContent="center"
          display={hide ? 'none' : 'flex'}
          flexDirection="row"
          gap={4}
        >
          {children}
        </Box>
      </Light>
    </Box>
  );
};

export default Card;
